.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}

.Category {
  width: 500px;
  display: flex;
  justify-content: space-between;
}

p {
  margin: 5px;
}

h2 {
  margin: 15px;
}

.Title {
  display: flex;
  align-items: center;
}

button {
  height: 20px;
}
